import {initializeApp} from "firebase/app";
import {getMessaging, getToken, onMessage } from "firebase/messaging";
import axios from "axios";

const firebaseConfig = {
    apiKey: "AIzaSyCEK8vLfNePewetr0MrQBFo9gztfSodNlw",
    authDomain: "pls2alarm.firebaseapp.com",
    projectId: "pls2alarm",
    storageBucket: "pls2alarm.appspot.com",
    messagingSenderId: "1089872813324",
    appId: "1:1089872813324:web:e9a9651e8e378bd7a8fd29",
    measurementId: "G-H0XMK85E5H"
};

const firebaseApp= initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

if('serviceWorker' in navigator) {
    navigator.serviceWorker.register('/firebase-messaging-sw.js' )
        .then(function (registration) {
            console.log('Service Worker registered with scope:', registration.scope);
        }).catch(function (err) {
        console.error('Service Worker registration failed:', err);
    });
}

onMessage(messaging, (payload) => {
    console.log('Message received: ' , payload);
    const notificationTitle = payload.notification.title;
    const notificationOptions = {
        body: payload.notification.body,
        icon: '/icons/icon_120x120.jpg'
    };

    if(Notification.permission === 'granted')
        new Notification(notificationTitle, notificationOptions);
})

export function requestPermission() {
    void Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
            getToken(messaging, { vapidKey: 'BPm2g2Ma5KNt-izEuWusiE_-F-IQdjBpQskZe0MyHmJCX5U2UJC3waa0v7N8EKVc7WRzvlpDUX-FYotCJvmEV_o'}).then((token) => {
                const data = {
                    userId: localStorage.getItem("userId"),
                    token: token,
                    uuid : getBrowserUUID(),
                    OS: getOSInfo(),
                    host: window.location.hostname
                };
                const _axios = axios.create();
                _axios.post("https://"+process.env.VUE_APP_BASE_URL+":45000/api/token", data, {
                    headers:{
                        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    }
                })
                .then(response => {
                    console.log(response.data);
                });

            }).catch((error) => {
                console.error(error);
            })
        } else if(permission === 'denied'){
            console.log('push block');
        }
    });
}

function generateUUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

function getBrowserUUID() {
    let uuid = localStorage.getItem('browserUUID');
    if (!uuid) {
        uuid = generateUUID();
        localStorage.setItem('browserUUID', uuid);
    }
    return uuid;
}

function getOSInfo() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/windows phone/i.test(userAgent)) {
        return "Windows Phone";
    }
    if (/win/i.test(userAgent)) {
        return "Windows";
    }
    if (/android/i.test(userAgent)) {
        return "Android";
    }
    if (/linux/i.test(userAgent)) {
        return "Linux";
    }
    if (/iphone|ipad|ipod/i.test(userAgent)) {
        return "iOS";
    }
    if (/mac/i.test(userAgent)) {
        return "MacOS";
    }

    return "Unknown OS";
}