<script setup>

</script>

<template>
  <v-app>
    <v-container fluid>
      <v-combobox outlined label="Input PV name" hint="PV 이름을 입력하세요" v-model="pvName"></v-combobox>
      <v-btn
          class="btn_verify"
          depressed
          color="primary"
          v-on:click="getPvInfo"
          :loading="verifyLoading"
          :disabled="verifyLoading"
      >
        Verify
      </v-btn>

      <v-dialog v-model="isDialogVisible" max-width="300">
        <v-card>
          <v-card-title class="headline">Alert</v-card-title>
          <v-card-text>
            {{dialogMessage}}
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" text @click="closeDialog">OK</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
    <component :is="addConfigComponent" v-bind:pvData="pvName"></component>
  </v-app>
</template>

<script>
import { defineAsyncComponent, shallowRef } from "vue";

const AddConfig = defineAsyncComponent(() => import('@/components/mAddConfig.vue'))

export default {
  data(){
    return{
      pvName: '',
      verifyLoading: false,
      isDialogVisible: false,
      dialogMessage: '',
      addConfigComponent: shallowRef(null),
      result: false,
    }
  },
  methods:{
    async getPvInfo(){
      this.verifyLoading = true;
      const respPvVerify = await this.$http.get('https://' + process.env.VUE_APP_BASE_URL + ':45000/api/getData', {
        params: {
          userId: localStorage.getItem('userId'),
          pvName: this.pvName,
        },
      });

      const data = respPvVerify.data;

      if(data === 'O'){
        this.result = true;
        this.addConfigComponent = AddConfig;
      } else {
        this.result = false;
        this.dialogMessage = 'PV를 찾을 수 없거나 이미 등록됨'
        this.isDialogVisible = true;
      }

      this.verifyLoading = false;
    },
    closeDialog(){
      this.dialogMessage = '';
      this.isDialogVisible = false;
    }
  }
}
</script>

<style scoped>
.btn_verify{
  display: flex;
  float: right;
  justify-content: flex-end;
}
</style>