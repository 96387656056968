<template>

  <header class="haeder">
    <v-app-bar
        color="surface-variant"
        dark
    >
      <v-btn @click="goToIndex">
        <v-toolbar-title>PLS-II Alarm Service</v-toolbar-title>
      </v-btn>

      <v-spacer></v-spacer>
      <v-btn id="btn_ring" icon="mdi-bell-sleep"></v-btn>
      <v-btn v-if="$global.login" id="btn_setting" icon="mdi-cog-outline" class="mr-3" @click="goToSetting"></v-btn>
      <v-btn v-if="!$global.login" id="btn_login" icon="mdi-login" @click="goToLogin"></v-btn>
      <v-btn v-if="$global.login" id="btn_logout" icon="mdi-logout" @click="goToLogout"></v-btn>
    </v-app-bar>
  </header>
</template>

<script setup>
import { onMounted } from "vue";
import { requestPermission} from "@/firebase-sdk";

onMounted(()=> {
  const btn = document.querySelector("#btn_ring");
  btn.addEventListener('click', requestPermission);
});

</script>

<script>
export default {
  data() {
    return {
    };
  },
  methods: {
    goToLogin() {
      this.$global.login = false;
      this.$router.replace(`/login` );
    },
    goToLogout() {
      const data = localStorage.getItem("userId");
      this.$http.post("https://" + process.env.VUE_APP_BASE_URL + ":45000/api/logout", data, {
        headers:{
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        }
      })
      .then(response => {
        console.log(response.data['message']);
        if(response.data['message'] === "USER_LOGOUT"){
          this.$global.login = false;
          localStorage.removeItem('access_token');
          localStorage.removeItem('userId');
          window.location.reload();
        }
      });


      this.$router.replace(`/` );
    },
    goToSetting(){
      this.$router.replace(`/setting`);
    },
    goToIndex(){
      this.$router.replace(`/` );
    }
  },
  mounted() {
    const data = {
      userId: localStorage.getItem("userId"),
    };

    this.$http.post("https://"+process.env.VUE_APP_BASE_URL+":45000/api/auth", data, {
          headers:{
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          }
        })
        .then(response => {
          if(response.data['message'] === "ACCESS_TOKEN_OK"){
            this.$global.login = true;
            localStorage.setItem('access_token', response.data['accessToken']);
          } else if(response.data['message'] === "ACCESS_TOKEN_REFRESHED"){
            this.$global.login = true;
            localStorage.setItem('access_token', response.data['accessToken']);
            window.location.reload();
          } else if(response.data['message'] === "USER_EXPIRED_REFRESH_TOKEN"){
            this.$global.login = false;
            this.$router.replace(`/` );
          } else if(response.data['message'] === "USER_TOKEN_NOT_FOUND"){
            this.$global.login = false;
            this.$router.replace(`/` );
          } else if(response.data['message'] === "INVALID_ACCESS_TOKEN"){
            this.$global.login = false;
            this.$router.replace(`/` );
          } else if(response.data['message'] === "INVALID_TOKEN"){
            this.$global.login = false;
            this.$router.replace(`/` );
          }
        })
        .catch(() => {
          this.$global.login = false;
          this.$router.replace(`/` );
        })
  }
};
</script>


<style scoped>
v-toolbar-title{
  font-size: clamp(14px, 2vw, 18px);
}
</style>