import {createRouter, createWebHistory} from 'vue-router'
import mIndex from './components/mIndex.vue'
import mLogin from './components/mLogin.vue'
import mRegister from './components/mRegister.vue'
import mVerify from './components/mVerify.vue'
import mSetting from './components/mSetting.vue'
import mAddPv from './components/mAddPv.vue'

const routes = [
    {
        path: '/',
        name: 'Home',
        component: mIndex
    },
    {
        path: '/login',
        name: 'Login',
        component: mLogin,
    },
    {
        path: '/register',
        name: 'Register',
        component: mRegister,
    },
    {
        path: '/verify/:userId',
        name: 'Verify',
        component: mVerify,
    },
    {
        path: '/setting',
        name: 'Setting',
        component: mSetting,
    },
    {
        path: '/addPv',
        name: 'AddPv',
        component: mAddPv,
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});




export default router;