<template>
  <v-container class="signup-container">
    <v-row justify="center">
      <v-col cols="12" sm="8" md="6">
        <div v-if="isLoading" class="loading-overlay">
          <p>Processing...</p>
        </div>
        <v-card>
          <v-card-title class="text-center">Sign Up</v-card-title>

          <v-dialog v-model="isDialogVisible" max-width="300">
            <v-card>
              <v-card-title class="headline">Alert</v-card-title>
              <v-card-text>
                {{dialogMessage}}
              </v-card-text>
              <v-card-actions>
                <v-btn color="primary" text @click="closeDialog">OK</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-card-text>
            <v-form v-model="valid">
              <v-text-field
                  v-model="email"
                  label="Email"
                  type="email"
                  :rules="[rules.required, rules.email]"
                  prepend-inner-icon="mdi-email"
                  outlined
              ></v-text-field>

              <v-text-field
                  v-model="password"
                  label="Password"
                  type="password"
                  :rules="[rules.required, rules.min(6)]"
                  prepend-inner-icon="mdi-lock"
                  outlined
              ></v-text-field>

              <v-btn :disabled="!valid" color="primary" block @click="signup">
                Sign Up
              </v-btn>
            </v-form>
          </v-card-text>

          <v-card-actions class="justify-center">
            <v-btn text @click="goToLogin">Already have an account? Login</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      email: '',
      password: '',
      valid: false,
      isLoading: false,
      rules: {
        required: (value) => !!value || 'Required.',
        email: (value) => /^[a-zA-Z0-9._%+-]+@postech\.ac\.kr$/.test(value) || 'E-mail must be valid @postech.ac.kr address',
        min: (minLength) => (value) => value.length >= minLength || `Min ${minLength} characters.`,
      },
      isDialogVisible: false,
      dialogMessage: '',
    };
  },
  methods: {
    signup() {
      this.isLoading = true;
      const data = this.email + ';' + this.password;
      this.$http.post('https://' + process.env.VUE_APP_BASE_URL + ':45000/api/register', data, {timeout: 20000})
          .then((response) => {
            if(response.data['message'] === "REGISTRATION_OK"){
              this.$router.replace({
                name: 'Verify',
                params: { userId: this.email }
              });
            }else{
              this.dialogMessage = response.data;
              this.isDialogVisible = true;
            }
          })
          .catch(() => {
          })
          .finally(() => {
            this.isLoading = false;
          });

    },
    goToLogin() {
      this.$router.replace('/login');
    },
    closeDialog(){
      this.isDialogVisible = false;
    }
  },
};

</script>

<style scoped>
.signup-container {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  z-index: 9999;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  font-size: 1.5em;
}
</style>