<script setup>
import PvCard from "@/components/mPvCard.vue"
</script>

<template>
  <div class="main-content">
    <v-container fluid>
      <ul v-if="!isLogin && isReceived">
        <li>
          <pv-card v-bind:pvdata="pvs[0]"></pv-card>
        </li>
      </ul>


      <ul v-if="isLogin && isReceived">
        <li v-for="pv in pvs" :key="pv.pvName">
          <pv-card v-bind:pvdata="pv"></pv-card>
        </li>
      </ul>

      <!--
      <v-row class="chart-row">
        <v-col cols="12" md="6" sm="6">
          <h2 class="line-chart-title">{{beamDeltaXProps.title}}</h2>
          <line-chart v-bind:pvdata="beamDeltaXProps"></line-chart>
        </v-col>
        <v-col cols="12" md="6" sm="6">
          <h2 class="line-chart-title">{{beamDeltaYProps.title}}</h2>
          <line-chart v-bind:pvdata="beamDeltaYProps"></line-chart>
        </v-col>
      </v-row>
      <v-row class="chart-row" >
        <v-col cols="12" md="6" sm="6">
          <h2 class="line-chart-title">{{beamTuneXProps.title}}</h2>
          <line-chart v-bind:pvdata="beamTuneXProps"></line-chart>
        </v-col>
        <v-col cols="12" md="6" sm="6">
          <h2 class="line-chart-title">{{beamTuneYProps.title}}</h2>
          <line-chart v-bind:pvdata="beamTuneYProps"></line-chart>
        </v-col>
      </v-row>
      <v-row class="chart-row" >
        <v-col cols="12" md="6" sm="6">
          <h2 class="line-chart-title">{{beamSizeXProps.title}}</h2>
          <line-chart v-bind:pvdata="beamSizeXProps"></line-chart>
        </v-col>
        <v-col cols="12" md="6" sm="6">
          <h2 class="line-chart-title">{{beamSizeYProps.title}}</h2>
          <line-chart v-bind:pvdata="beamSizeYProps"></line-chart>
        </v-col>
      </v-row>
      -->
    </v-container>
  </div>
</template>

<script>

var wSock;

export default {
  data() {
    return{
      pvBuffer: [],
      pvs: '',
      isReceived: false,
    }
  },
  computed: {
    isLogin(){
      return this.$global.login;
    },
    changeWebSocketChange : {
      get: function(){
        return this.webSockState;
      },
      set: function(val){
        this.webSockState = val;
      }
    }
  },
  methods:{
    webSocketHandler(){
      wSock = new WebSocket("wss://"+process.env.VUE_APP_BASE_URL+":45001/api/getData");
      let vm = this;

      wSock.onopen = function(){
        if(vm.$global.login) {
          wSock.send(localStorage.getItem('userId'));
        }else{
          wSock.send('Guest');
        }

        vm.changeWebSocketChange ='Connected';

        vm.pingpong = setInterval(() =>{
          if(vm.$global.login){
            wSock.send(localStorage.getItem('userId'));
          }else{
            wSock.send('Guest');
          }
        }, 3000);

        wSock.onmessage = (event) => {
          if(event.isTrusted === true){
            try {
              if(event.data !== "Web Socket Registered") {
                const data = JSON.parse(event.data);
                vm.isReceived = true;
                vm.pvs = data;
              }
            } catch (e){
              console.log(e);
            }
          }
        };
      };

      wSock.onclose = function () {
        clearInterval(vm.pingpong);
        vm.changeWebSocketChange = 'Disconnected';
      }
    }
  },
  beforeUnmount() {
    if(wSock){
      wSock.close();
    }
  },
  mounted() {
    this.webSocketHandler();
  },
}

/*
let isLocked = false;

document.addEventListener('visibilitychange', function(){
  if(document.hidden){
    isLocked = true;
  } else {
    if(isLocked && wSock.readyState !== WebSocket.OPEN){
      this.webSocketHandler();
      isLocked = false;
    }
  }
})
*/
</script>

<style scoped>
v-container{
  width: 100%;
}

.main-content{
  width: 100%;
}

.v-application ul, .v-application ol{
  list-style-type: none;
  padding-left: 0;
}
</style>