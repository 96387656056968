<template>
  <div class="line-chart-container">
    <Line :options="chartOptions" :data="chartData" ></Line>
  </div>
</template>

<script>
import { Line } from 'vue-chartjs'
import { Chart as ChartJS, Filler, Title, Tooltip, Legend, LineElement, CategoryScale, LinearScale, PointElement } from 'chart.js';

ChartJS.register(Filler, Title, Tooltip, Legend, LineElement, CategoryScale, LinearScale, PointElement);

export default {
  props: ['pvdata'],
  components: {
    Line,
  },
  data() {
    return {
      title: this.pvdata['title'],
      chartData: {
        labels: [],
        datasets: [
          {
            label: 'time',
            borderColor: '#0000FF',
            data: [],
            fill: true,
            backgroundColor: 'rgba(0, 0, 255, 0.1)',
            pointRadius: 1,
          }
        ]
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        responsiveAnimationDuration: 0,
        plugins: {
          legend : {
            display: false,
          },
          decimation: {
            enabled: true,
            algorithm: 'lttb',
            samples: 360,
          }
        },
        scales: {
          x: {
            ticks: {
              autoSkip: true,
              display: true,
              maxTicksLimit: 4,
              maxRotation: 0,
              minRotation: 0,
            },
            time: {
              displayFormats:{
                'millisecond': 'HH:mm:ss',
                'second': 'HH:mm:ss',
                'minute': 'HH:mm:ss',
                'hour': 'MM/DD HH',
                'day': 'MM/DD',
                'week': 'MMM DD',
                'month': 'MMM DD',
                'quarter': 'MMM DD',
                'year': 'MMM DD'
              }
            },
            title: {
              display: true,
              text: 'Time',
            },
          },

          y: {
            ticks: {
              autoSkip: true,
            },
            title: {
              display: true,
              text: this.pvdata['unit'],
            },
            //min: this.pvdata['yAxisMin'],
          },
        }
      },
      isLoading: false,

    }
  },

  methods: {
    async fetchChartData() {

      let pvName = this.pvdata['pvName'];
      let time = this.pvdata['time'];

      if(this.isLoading) {
        return;
      }

      this.isLoading = true;

      try {
        const response = await this.$http.get('https://' + process.env.VUE_APP_BASE_URL + ':45000/api/getData',{
              params: {
                pvName: pvName,
                hour: time,
              },
            }
          );
        const data = response.data[0].data;

        var labels = [];
        var pvValues = [];

        //if(this.pvdata['decimation'] === 'lttb'){
          const formattedData = data.map(item => {
            const timeInSeconds = item.secs + item.nanos / 1e9;
            const timeInKST = timeInSeconds + 9 * 60 * 60;
            return [timeInKST * 1000, item.val];
          });

          const lttbData = this.largestTriangleThreeBuckets(formattedData, 3600);

          labels = lttbData.map(point => {
            const date = new Date(point[0]);
            return date.toISOString().substr(11, 8);
          });
          pvValues = lttbData.map(point => point[1]);
       /* } else {
          data.forEach(item => {
            const timeInSeconds = item.secs + item.nanos / 1e9;
            const date = timeInSeconds * 1000
            const time = new Date(date);
            const kstDate = new Date(time.getTime() + 9 * 60 * 60 * 1000);


            if (!isNaN(time.getTime())) {
              const timeLabel = kstDate.toISOString().substr(11, 12);
              const formattedTimeLabel = timeLabel.slice(0, 10);
              labels.push(formattedTimeLabel);
              pvValues.push(item.val);
            } else {
              console.error("invalid: " + time);
            }
          });
        }*/

        //this.chartOptions.scales.x.ticks.maxTicksLimit = window.innerWidth / 100;
        //this.chartOptions.scales.y.ticks.maxTicksLimit = window.innerWidth / 150;

        this.chartData = {
          labels: labels,
          datasets: [
            {
              data: pvValues,
              label: this.pvdata['desc'],
              borderColor: '#0000FF',
            }
          ]
        }

      }catch (e) {
        console.error(e);
      }finally {
        this.isLoading = false;
      }
    },

    largestTriangleThreeBuckets(data, threshold) {
      if (threshold >= data.length || threshold === 0) {
        return data;
      }

      const sampled = [];
      const bucketSize = (data.length - 2) / (threshold - 2);

      let a = 0;
      let maxAreaPoint, maxArea, area, nextA;

      sampled.push(data[a]);

      for (let i = 0; i < threshold - 2; i++) {
        const rangeStart = Math.floor((i + 1) * bucketSize) + 1;
        const rangeEnd = Math.min(Math.floor((i + 2) * bucketSize) + 1, data.length);
        const range = data.slice(rangeStart, rangeEnd);

        let avgX = 0, avgY = 0;
        for (const point of range) {
          avgX += point[0];
          avgY += point[1];
        }
        avgX /= range.length;
        avgY /= range.length;

        const rangeOffs = Math.floor(i * bucketSize) + 1;
        const rangeLen = rangeEnd - rangeStart;

        maxArea = -1;

        for (let j = 0; j < rangeLen; j++) {
          area = Math.abs(
              (data[a][0] - avgX) * (range[j][1] - data[a][1]) -
              (data[a][0] - range[j][0]) * (avgY - data[a][1])
          );
          if (area > maxArea) {
            maxArea = area;
            maxAreaPoint = range[j];
            nextA = rangeOffs + j;
          }
        }

        sampled.push(maxAreaPoint);
        a = nextA;
      }

      sampled.push(data[data.length - 1]);

      return sampled;
    },
  },
  mounted() {
    this.fetchChartData();
  }
}

</script>

<style scoped>
.line-chart-container{
  position: relative;
  width: 100%;
  height: 400px;
}

@media (max-width: 600px){
  .line-chart-container{
    position: relative;
    width: 100%;
    height: 200px;
  }
}

@media (min-width: 601px){
  .line-chart-container{
    position: relative;
    width: 100%;
    height: 400px;
  }
}

h2{
  font-size: clamp(14px, 2vw, 18px);
}
</style>