<script setup>

</script>

<template>
  <v-container class="login-container">
    <v-row justify="center">
      <v-col cols="12" sm="8" md="6">
        <div v-if="isLoading" class="loading-overlay">
          <p>Processing...</p>
        </div>
        <v-card>
          <v-card-title class="text-center">Verify</v-card-title>
          <v-card-text>
            <v-form v-model="valid">
              <v-text-field
                  v-model="verifyCode"
                  label="Code"
                  type="text"
                  outlined
              ></v-text-field>
              <v-btn :disabled="!valid" color="primary" block @click="checkVerifyCode">
                Verify
              </v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      valid: false,
      userId: '',
      verifyCode: '',
      isLoading: false,
    };
  },
  methods:{
    checkVerifyCode(){
      this.isLoading = true;
      const data = this.userId + ';' + this.verifyCode;

      this.$http.post('https://' + process.env.VUE_APP_BASE_URL + ':45000/api/verify', data, {withCredentials: true, timeout: 20000})
          .then((response) => {
            console.log(response.data);
            if(response.data['message'] === "VERIFY_OK"){
              localStorage.setItem('userId', this.userId);
              this.$router.replace('/login');
            }else{
              this.dialogMessage = response.data;
              this.isDialogVisible = true;
            }
          })
          .catch(() => {
          })
          .finally(() => {
            this.isLoading = false;
          });
    }
  },
  computed: {
  },

  mounted(){
    this.userId = this.$route.params.userId;
  }

}
</script>

<style scoped>
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  font-size: 1.5em;
  z-index: 9999;
}
</style>