<template>
  <v-app>
    <Header />
    <div class="header-spacing"></div>
    <div class="main-view">
      <router-view/>
    </div>
  </v-app>
</template>


<script>
import Header from './components/mHeader.vue'

export default {
  name: 'App',
  components: {
    Header,
  }
};

</script>

<style scoped>
.header-spacing {
  height: 70px;
}

.main-view{
  display: flex;
  justify-content: center;
}
</style>