<script setup>

if(localStorage.getItem('userId') !== '' && localStorage.getItem('verify') !== ''){
  console.log('GUEST');
}


</script>

<template>
  <v-container class="login-container">
    <v-row justify="center">
      <v-col cols="12" sm="8" md="6">
        <v-card>
          <v-card-title class="text-center">Login</v-card-title>
          <v-dialog v-model="isDialogVisible" max-width="300">
            <v-card>
              <v-card-title class="headline">Alert</v-card-title>
              <v-card-text>
                {{dialogMessage}}
              </v-card-text>
              <v-card-actions>
                <v-btn color="primary" text @click="closeDialog">OK</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-card-text>
            <v-form v-model="valid">
              <v-text-field
                  v-model="email"
                  label="Email"
                  type="email"
                  :rules="[rules.required, rules.email]"
                  :success="isEmailValid ? 'mdi-check-circle' : ''"
                  :error="!isEmailValid && email !== ''"
                  prepend-inner-icon="mdi-email"
                  outlined
              ></v-text-field>

              <v-text-field
                  v-model="password"
                  label="Password"
                  type="password"
                  :rules="[rules.required, rules.min(6)]"
                  prepend-inner-icon="mdi-lock"
                  outlined
              ></v-text-field>

              <v-btn :disabled="!valid" color="primary" block @click="login">
                Login
              </v-btn>
            </v-form>
          </v-card-text>

          <v-card-actions class="justify-center">
            <v-btn text @click="goToRegister">Sign up</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      email: '',
      password: '',
      valid: false,
      isDialogVisible: false,
      rules: {
        required: (value) => !!value || 'Required.',
        email: (value) => /^[a-zA-Z0-9._%+-]+@postech\.ac\.kr$/.test(value) || 'E-mail must be valid @postech.ac.kr address',
        min: (minLength) => (value) => value.length >= minLength || `Min ${minLength} characters.`,
      },
    };
  },
  methods: {
    login() {
      const data = this.email + ';' + this.password;
      this.$http.post('https://' + process.env.VUE_APP_BASE_URL + ':45000/api/login', data, {timeout: 20000, withCredentials: true})
          .then((response) => {
            if(response.data['message'] === "LOGIN_OK"){
              localStorage.setItem('access_token', response.data['accessToken']);
              localStorage.setItem('userId', response.data['userId']);
              window.location.href = '/';
              this.$login = true;
            }else{
              this.dialogMessage = response.data['message'];
              this.isDialogVisible = true;
            }
          })
          .catch((e) => {
            console.log(e);
          })
          .finally(() => {
            this.isLoading = false;
          });
    },
    goToRegister() {
      this.$router.replace('/register');
    },
    closeDialog(){
      this.isDialogVisible = false;
    }
  },
  computed: {
    isEmailValid() {
      return /^[a-zA-Z0-9._%+-]+@postech\.ac\.kr$/.test(this.email);
    }
  }
};
</script>

<style scoped>
.login-container {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>