<script setup>

import LineChart from "@/components/chart/lineChart.vue";
</script>

<template>
  <v-card
      class="mx-auto"
      color="white"
  >
    <v-card-actions :styles="{height: '30px'}">
      <v-btn text @click="show = !show">{{pvdata.desc === '' ? pvdata.pvName : pvdata.desc}}</v-btn>
      <v-spacer></v-spacer>
      {{ pvdata.value === undefined ? "No Data" : pvdata.value.toPrecision(5) }}
      {{ pvdata.unit !== '-' ? pvdata.unit : '' }}
      <v-btn
          icon
          @click="show = !show"
      >
        <v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
      </v-btn>
    </v-card-actions>

    <v-expand-transition>
      <div v-if="show">
        <v-divider></v-divider>
        <line-chart v-bind:pvdata=pvdata></line-chart>
      </div>
    </v-expand-transition>
  </v-card>
</template>

<script>



export default {
  props: ['pvdata'],
  data(){
    return{
      show: false,
    }
  },
  mounted() {

  },
  computed:{

  }
}
</script>

<style scoped>

</style>