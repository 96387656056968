<script setup>

</script>

<template>
  <div class="main-content">
    <v-container>
      <v-list>
        <br>
        <v-list-item to="/addPv">
            <v-list-item-title>PV 추가</v-list-item-title>
            <v-list-item-subtitle>본인 계정에 새로운 PV를 추가합니다</v-list-item-subtitle>
        </v-list-item>
          <br>
          <v-divider></v-divider>
          <br>
        <v-list-item>
            <v-list-item-title>PV 관리</v-list-item-title>
            <v-list-item-subtitle>본인 계정의 PV 설정을 관리합니다</v-list-item-subtitle>
        </v-list-item>
      </v-list>
    </v-container>
  </div>
</template>

<script>

export default {

}
</script>

<style scoped>
v-container{
  width: 100%;
}


.main-content{
  width: 100%;
}

</style>